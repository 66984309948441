<template>
  <v-container fluid v-if="view.public">
    <message-card>
      <h2>No Public view yet</h2>
    </message-card>
  </v-container>
  <v-container fluid v-else-if="!division.hydrated && false">
    <message-card>
      <h2>Loading</h2>
    </message-card>
  </v-container>
  <v-container fluid class="pa-0" v-else>
    <loading-bar :value="patching"></loading-bar>
    <v-row dense>
      <v-col cols="12">
        <v-container fluid class="pt-0">
          <v-row dense>
            <v-col cols="12" class="xsmall text-right py-0">
              Registration is currently: {{division.regOpen ? 'Open' : 'Closed'}} | Refund/Change window is currently: {{division.refundsOpen ? 'Open' : 'Closed'}}
            </v-col>
            <v-col cols="12" class="text-right py-0">
              <cancel-division-dialog></cancel-division-dialog>
            </v-col>
          </v-row>
          <v-row dense v-if="!tournament.locked && !division.complete">
            <v-switch
              label="Publish Registered Teams"
              v-model="division.showTeams"
              hide-details
              class="shrink mr-2 mt-0"
              color="success"
            ></v-switch>
            <setting-toggle
              v-if="division.maxTeams !== null"
              :dataId="division.id"
              label="Hide Remaining Spots"
              propName="hideSpots"
              :isDivisionProp="true"
              :jProp="true"
              iClass="mr-3 shrink mt-0"
            ></setting-toggle>
            <setting-toggle
              v-if="isTournament"
              :dataId="division.id"
              label="Hide Seeds"
              propName="hideSeed"
              :isDivisionProp="true"
              :jProp="true"
              iClass="mr-3 shrink mt-0"
            ></setting-toggle>
            <v-spacer></v-spacer>
            <v-switch
              v-if="false"
              label="Wait List"
              v-model="division.waitlist"
              hide-details
              class="shrink mr-2 mt-0"
              color="color3"
            ></v-switch>
            <v-switch
              label="Disable Registration"
              v-model="division.disableRegistration"
              hide-details
              color="color3"
              class="shrink mr-2 mt-0"
            ></v-switch>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <division-editor :tournamentIn="tournament" :divisionId="divisionId"></division-editor>
      </v-col>
    </v-row>
    <v-row v-if="(user && division.isCompetitive) || (user && user.vbl)">
      <v-col cols="12">
        <v-btn color="color3" text @click.stop="addStandings">Add Grouped Standings</v-btn>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import DivisionEditor from '@/components/Tournament/Edit/TournamentEditor.vue'
import SettingToggle from '@/components/Tournament/QuickSettings/SettingToggle.vue'
import { mapGetters } from 'vuex'
import CancelDivisionDialog from '@/components/Tournament/Division/CancelDivisionDialog'
import GroupedStandings from '@/classes/Standings/GroupedStandings'

export default {
  mixins: [RouteMixin],
  data () {
    return {
      patching: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getDivision', 'view', 'getDivisionJProp', 'user']),
    division () {
      return this.getDivision(this.divisionId)
    },
    isTournament () {
      return this.tournament.isTournament && this.division.isTournament
    },
    divisionStandings () {
      return this.division.standings
    }
  },
  methods: {
    patch (v) {
      const dto = {}
      dto[v] = this.division[v]

      this.patching = true
      this.division.patch(dto)
        .finally(() => {
          this.patching = false
        })
    },
    openAll () {
      if (this.$refs && this.$refs.xPanel) {
        this.panel = this.$refs.xPanel.items.map(m => true)
        this.panel[0] = false
      }
    },
    addStandings () {
      const i = this.division.standings.length
      this.division.standings.push(new GroupedStandings({ divisionId: this.divisionId }))
      this.$nextTick(() => {
        this.$router.push({ name: 'division-standings', params: { tournamentId: this.tournamentId, divisionId: this.divisionId, standingsI: i } })
      })
    }
  },
  watch: {
    'division.showTeams': function (val) {
      this.patch('showTeams')
    },
    'division.disableRegistration': function (val) {
      this.patch('disableRegistration')
    },
    'division.waitlist': function (val) {
      this.patch('waitlist')
    }
  },
  components: {
    DivisionEditor,
    SettingToggle,
    CancelDivisionDialog
  },
  mounted () {
    if (this.view.public) {
      this.$router.push({ name: 'division-nav', params: { tournamentId: this.tournamentId, divisionId: this.divisionId } })
    }
  }
}
</script>

<style scoped>
.bt {
  border-top: 1px solid white !important
}
</style>
