<template>
  <v-dialog
    v-model="dialog"
    persistent scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{on}">
      <v-btn
        v-on="on"
        color="color3"
        text x-small
      >Cancel Division</v-btn>
    </template>
    <v-card>
      <v-card-text class="title text-center pt-3">
        Are you sure?
      </v-card-text>
      <v-card-text>
        You will still have access to the teams in the tournament manager. But you will not be able to undo the cancellation.
      </v-card-text>
      <v-card-actions class="justify-center">
          <v-btn color="success" @click.stop="cancelDivision" :loading="loading">yes</v-btn>
          <v-btn color="error" @click.stop="dialog= false">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [RouteMixin],
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['getDivision']),
    division () {
      return this.getDivision(this.divisionId)
    }
  },
  methods: {
    cancelDivision () {
      this.loading = true
      this.division.patch({ canceled: true })
        .then(() => {
          this.$router.push(`/tournament/${this.tournamentId}/admin`)
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
          this.dialog = false
        })
    }
  }
}
</script>
